:root {
  --white: #ffffff;
  --red: #e31b23;
  --bodyColor: #ffffff;
  --borderFormEls :rgba(255, 255, 255, 1);
  --bgFormEls:rgba(255, 255, 255, 1);
  --bgFormElsFocus: rgba(255, 255, 255, 1);
}


.body-image {
  padding-top: 50px;
  width: 70%;
  margin: auto;
}

a {
  color: rgb(63, 63, 63);
}


input,
select,
textarea,
button {
  font-family: "Roboto";
  font-size: 14px;
}

label {
  color: rgb(0, 0, 0);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
}

select option{
  background: black;
}

/* Remove native arrow on IE */
select::-ms-expand {
  display: none;
}


select:-moz-focusring {
  color: black;
}

textarea {
  resize: none;
}

ul {
  list-style: none;
}

body {
  font: 18px/1.5 "Roboto";
  background: var(--bodyColor);
  color: var(--white);
}

.container {
  max-width: 700px;
  margin: 0 auto;
}
.container > h1{
  color: black;
}

.button-center {
  width: 200px;
  margin-left: auto;
  margin-right: auto;

}

.send-message {
  background-color:#ffffff;
  border: none;
  cursor: pointer;
  /*border-radius: 5%;*/
}

.send-message:hover{
  background-color: grey;
  color: white;
  border: 1px solid black;
}


/* FORM ELEMENTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form h1 {
  margin-bottom: 1.5rem;
}

.my-form li,
.my-form .grid > *:not(:last-child) {
  margin-bottom: 1.5rem;
}

.my-form select,
.my-form input,
.my-form textarea {
  width: 100%;
  line-height: 1.5;
  padding: 15px 10px;
  /*border: 1px solid var(--borderFormEls);*/
  color: black;
  transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
    transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.my-form textarea {
  height: 170px;
}

.my-form ::placeholder {
  color: grey;
  /*Fix opacity issue on Firefox*/
  opacity: 1;
}

.my-form select:focus,
.my-form input:focus,
.my-form textarea:focus,
.my-form button:enabled:hover,
.my-form button:focus,
.my-form input[type="checkbox"]:focus + label {
  background: var(--bgFormElsFocus);
}

.my-form select:focus,
.my-form input:focus,
.my-form textarea:focus {
  transform: scale(1.02);
}



/* FORM BTNS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form .required-msg {
  display: none;
  padding-left: 20px;
}

.my-form .btn-grid {
  position: relative;
  overflow: hidden;
  transition: filter 0.2s;
}

.my-form button {
  font-weight: bold;
}

.my-form button > * {
  display: inline-block;
  width: 100%;
  transition: transform 0.4s ease-in-out;
}

.my-form button .back {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-110%, -50%);
}

.my-form button:enabled:hover .back,
.my-form button:focus .back {
  transform: translate(-50%, -50%);
}

.my-form button:enabled:hover .front,
.my-form button:focus .front {
  transform: translateX(110%);
}


/* CUSTOM CHECKBOX
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.my-form input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  padding-left: 2rem;
  transition: background 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.my-form input[type="checkbox"] + label::before,
.my-form input[type="checkbox"] + label::after {
  content: '';
  position: absolute;
}

.my-form input[type="checkbox"] + label::before {
  left: 0;
  top: 6px;
  width: 18px;
  height: 18px;
  /*border: 2px solid var(--white);*/
}

.my-form input[type="checkbox"]:checked + label::before {
  background: var(--red);
}

.my-form input[type="checkbox"]:checked + label::after {
  left: 7px;
  top: 7px;
  width: 6px;
  height: 14px;
  /*border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);*/
  transform: rotate(45deg);
}



/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (min-width: 600px) {
  .my-form .grid {
    display: grid;
    grid-gap: 1.5rem;
  }

  .my-form .grid-2 {
    grid-template-columns: 1fr 1fr;
  }

  .my-form .grid-3 {
    grid-template-columns: auto auto auto;
    align-items: center;
  }

  .my-form .grid > *:not(:last-child) {
    margin-bottom: 0;
  }

  .my-form .required-msg {
    display: block;
  }
}

@media screen and (min-width: 541px) {
  .my-form input[type="checkbox"] + label::before {
    top: 50%;
    transform: translateY(-50%);
  }

  .my-form input[type="checkbox"]:checked + label::after {
    top: 3px;
  }
}

@media screen and (max-width: 500px) {
  .my-form{
    width: 100%;
  }
}

input[type="text"] + label
 {
  position: absolute;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #1f1f1f;
  cursor: text;
  transform-origin: top left;
  transition: all 0.1s;
  transform: translate(20px, -35px) scale(1);
}

input[type="text"]:focus + label,
input[type="text"]:not([value=""]) + label
 {
  transform: translate(10px,-50px) scale(0.7);
  color: none;
}
