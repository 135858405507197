* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}


.text-block2 {
position: initial;
display: block;
width: 80%;
height: auto;
margin-left: auto;
margin-right: auto;
align-items: center;
background-color: white;
color: black;
opacity: 0.5;
}