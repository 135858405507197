@import url(/node_modules/react-awesome-slider/src/core/styles.scss);
:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
  font-family: 'Roboto';
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  font-family: "Roboto";
}

.btn--outline-contact {
  background-color: #3b2222;
  color: rgb(22, 4, 4);
  padding: 8px 20px;
  border: 1px solid var(--primary);
  font-family: 'Roboto';
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
  font-family: 'Roboto';
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
  font-family: 'Roboto';
  color: rgb(22, 4, 4);
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}

.navbar {
  background: linear-gradient(90deg, #12285C 100%, rgba(255, 255, 255, 0) 100%);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1600px;

}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 27px;
  display: flex;
  align-items: center;
  font-family: "Roboto";
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: "Roboto";
  cursor: pointer;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.nav-links-special {
  color: #fff;
  display: flex;
  font-family: "Roboto";
  border: 2px solid #fff;
  padding: 14px 20px;
  transition: all 0.3s ease-out;
  font-family: 'Roboto';
  margin: 11px;
}

.nav-links-special:hover {
  background: #fff;
  color: #242424;
  transition: 250ms;
}


.fa-bars {
  color: #fff;
  font-weight: light ;
}

.bars div {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .nav-links-special {
    color: #fff;
    transition: 250ms;
    font-size: 1.05rem;
  }

  .nav-menu {
    font-size: 1.05rem;
  }
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #12285C;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-special {
    display: block;
    border: none;
  }
}

.logo{
  background: url('/images/Zuwen.jpeg') center center/cover no-repeat;
  background-size: contain; 
  /*position: absolute;*/
  width: 250px;
  height: 100px;
}

@media (max-width: 960px) {
  .logo{
    position: absolute;
    top: 0px;
    left: 30px;
  }
}

@media (max-width: 768px) {
  .logo{
    position: absolute;
    /*top: -35px;*/
    left: 30px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}


.text-block2 {
position: initial;
display: block;
width: 80%;
height: auto;
margin-left: auto;
margin-right: auto;
align-items: center;
background-color: white;
color: black;
opacity: 0.5;
}
.cards {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(0, 89, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #224AA8;
  font-size: 24px;
  line-height: 24px;
  font-family: 'Roboto';
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

.footer-container {
    background-color: #12285C;
    padding: 1.5rem 0 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-family: 'Roboto';
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
    font-family: 'Roboto';
  }
  
  .address {
    color: #fff;
    margin-bottom: 16px;
    display: block; 
    font-family: 'Roboto';
  }

  .address  > p{
    color: #fff;
    display: block; 
    font-family: 'Roboto';
  }

  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;

    }
    .address  > p{
      color: #fff;
      display: block; 
      font-family: 'Roboto';
      text-align: center;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
    footer-links {
      padding-top: 2rem;

    }
    
    .address  > p{
      color: #fff;
      display: block; 
      font-family: 'Roboto';
      text-align: center;
    }

    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }

  .footer-logo{
    background: url('/images/Zuwen.jpeg') center center/cover no-repeat;
    background-size: contain; 
    /*position: absolute;*/
    width: 250px;
    height: 100px;
  }
.awssld__container figure, .awssld__content, .awssld__box {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  
  .awssld__controls button, .awssld__bullets button {
    outline-color: 0;
    outline-style: none;
    outline-width: 0; }
  
  .awssld {
    --organic-arrow-thickness: 5px;
    --organic-arrow-height: 40px;
    --slider-height-percentage: 43%;
    --loader-bar-color: #000080;
    --loader-bar-height: 6px;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-opacity: 0.5;
    --control-button-hover-opacity: 0.75;
    --control-button-background: transparent;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 1000ms;
    --organic-arrow-color: #000080;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #000080;
    --control-bullet-active-color: #5858ff;
    --content-background-color: #fffff; }

  .awssld {
    display: block;
    position: relative;
    width: 100%;
    max-width: 100%; }
    .awssld__wrapper {
      display: block;
      overflow: hidden;
      position: relative;
      height: 100%;
      width: 100%; }
    .awssld__container {
      display: flex;
      width: 100%;
      height: 100%;
      padding-bottom: var(--slider-height-percentage); }

    .awssld__startUp {
      background-color: red;
      height: 100%;
      width: 100%; }
      .awssld__startUp > div {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
      .awssld__startUp img {
        width: 35%;
        height: auto; }
    .awssld__content {
      background-color: var(--content-background-color);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center; }
      .awssld__content > img,
      .awssld__content > video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
    .awssld__controls button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--control-button-width);
      height: var(--control-button-height);
      position: absolute;
      z-index: 10;
      top: calc(50% - (0.5 * var(--control-button-height)));
      border: none;
      background-color: var(--control-button-background);
      color: #fff;
      cursor: pointer; }


      @media all and (max-width: 500px) {
        .awssld__container {
          padding-bottom: calc(var(--slider-height-percentage) * 1.25); } 
        .awssld {
                --slider-height-percentage: 60%;
        }
      }
      @media all and (max-width: 450px) {
        .awssld__container {
          padding-bottom: calc(var(--slider-height-percentage) * 1.25); } 
        .awssld {
                --slider-height-percentage: 65%;
        }
        
            }
            @media all and (max-width: 400px) {
              .awssld__container {
                padding-bottom: calc(var(--slider-height-percentage) * 1.25); } 
        .awssld {
                --slider-height-percentage: 70%;
        }
            }
            @media all and (max-width: 320px) {
              .awssld__container {
                padding-bottom: calc(var(--slider-height-percentage) * 1.25); } 
        .awssld {
                --slider-height-percentage: 75%;
        }
            }



      .awssld__controls button .awssld__controls__arrow-left,
      .awssld__controls button .awssld__controls__arrow-right {
        opacity: var(--control-button-opacity); }
      .awssld__controls button:hover .awssld__controls__arrow-left,
      .awssld__controls button:hover .awssld__controls__arrow-right {
        opacity: var(--control-button-opacity-hover); }
    .awssld__controls--active .awssld__controls__arrow-left {
      opacity: var(--control-button-opacity-hover);
      transform: translate3d(-100%, 0, 0); }
    .awssld__controls--active .awssld__controls__arrow-right {
      opacity: var(--control-button-opacity-hover);
      transform: translate3d(100%, 0, 0); }
    .awssld__controls--hidden {
      display: none; }
    @media all and (max-width: 520px) {
      .awssld__controls {
        visibility: hidden; } }
    .awssld__bar {
      display: block;
      width: 100%;
      height: var(--loader-bar-height);
      background-color: var(--loader-bar-color);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 101;
      transition: transform 3000ms var(--transition-bezier);
      transform: translate3d(-100%, 0, 0); }
      .awssld__bar--active {
        transform: translate3d(-20%, 0, 0); }
      .awssld__bar--end {
        transition-duration: 300ms;
        transform: translate3d(0, 0, 0); }
    .awssld__next {
      right: 0; }
    .awssld__prev {
      left: 0; }
    .awssld__box {
      z-index: 1;
      visibility: hidden; }
    .awssld--animated {
      will-change: transform;
      visibility: visible; }
    .awssld--animated-mobile {
      will-change: transform;
      transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1); }
    .awssld--active {
      visibility: visible;
      z-index: 2;
      transform: translate3d(0, 0, 0); }
    .awssld--moveRight, .awssld--moveLeft {
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden; }
    .awssld--moveRight {
      animation: slideFromRight var(--slider-transition-duration) both var(--transition-bezier); }
    .awssld--moveLeft {
      animation: slideFromLeft var(--slider-transition-duration) both var(--transition-bezier); }
    .awssld--exit {
      z-index: 0; }
      .awssld--exit.awssld--moveLeft {
        animation: slideToLeft var(--slider-transition-duration) both var(--transition-bezier); }
      .awssld--exit.awssld--moveRight {
        animation: slideToRight var(--slider-transition-duration) both var(--transition-bezier); }
    .awssld--first .awssld__prev {
      visibility: hidden; }
    .awssld--last .awssld__next {
      visibility: hidden; }
    .awssld--fill-parent {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0; }
      .awssld--fill-parent .awssld__container {
        height: 100%;
        padding: 0; }
    .awssld__bullets {
      position: absolute;
      bottom: -40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .awssld__bullets button {
        padding: 0;
        display: block;
        width: 16px;
        height: 16px;
        margin: 5px;
        border-radius: 50%;
        background: var(--control-bullet-color);
        text-indent: -9999px;
        overflow: hidden;
        cursor: pointer;
        border: none;
        transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out; }
        .awssld__bullets button:hover {
          transform: scale(1.2); }
      .awssld__bullets .awssld__bullets--loading {
        transform: scale(1.2); }
      .awssld__bullets .awssld__bullets--active {
        transform: scale(1.5);
        background: var(--control-bullet-active-color); }
        .awssld__bullets .awssld__bullets--active:hover {
          transform: scale(1.5); }
    .awssld__controls__arrow-left, .awssld__controls__arrow-right {
      width: 100%;
      height: var(--organic-arrow-height);
      position: relative;
      display: block;
      transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out; }
      .awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:before, .awssld__controls__arrow-right:after {
        content: ' ';
        position: absolute;
        right: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2);
        height: 100%;
        border-radius: var(--organic-arrow-border-radius);
        width: var(--organic-arrow-thickness);
        background-color: var(--organic-arrow-color);
        transition: transform 0.15s ease-out, background-color 0.15s ease-out; }
      .awssld__controls__arrow-left:before, .awssld__controls__arrow-right:before {
        transform-origin: 100% 100% 0;
        top: -50%;
        transform: rotate(-45deg); }
      .awssld__controls__arrow-left:after, .awssld__controls__arrow-right:after {
        transform-origin: 100% 0% 0;
        top: 50%;
        transform: rotate(45deg); }
    .awssld__controls__arrow-right--active {
      transform: translate3d(100%, 0, 0); }
      .awssld__controls__arrow-right--active:after {
        transform: rotate(90deg) translate3d(50%, 0, 0) !important; }
      .awssld__controls__arrow-right--active:before {
        transform: rotate(-90deg) translate3d(50%, 0, 0) !important; }
    .awssld__controls__arrow-left:before, .awssld__controls__arrow-left:after {
      right: auto;
      left: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2); }
    .awssld__controls__arrow-left:before {
      transform-origin: 0 100% 0;
      top: -50%;
      transform: rotate(45deg); }
    .awssld__controls__arrow-left:after {
      transform-origin: 0 0 0;
      top: 50%;
      transform: rotate(-45deg); }
    .awssld__controls__arrow-left--active {
      transform: translate3d(-100%, 0, 0); }
      .awssld__controls__arrow-left--active:after {
        transform: rotate(-90deg) translate3d(-50%, 0, 0) !important; }
      .awssld__controls__arrow-left--active:before {
        transform: rotate(90deg) translate3d(-50%, 0, 0) !important; }
    .awssld__controls button:hover .awssld__controls__arrow-left:before {
      opacity: 1;
      transform: rotate(30deg); }
    .awssld__controls button:hover .awssld__controls__arrow-left:after {
      opacity: 1;
      transform: rotate(-30deg); }
    .awssld__controls button:hover .awssld__controls__arrow-right:before {
      opacity: 1;
      transform: rotate(-30deg); }
    .awssld__controls button:hover .awssld__controls__arrow-right:after {
      opacity: 1;
      transform: rotate(30deg); }
    .awssld__timer {
      --timer-delay: 2000ms;
      --timer-release: 200ms;
      --timer-height: 4px;
      --timer-background-color: rgba(0, 0, 0, 0.15);
      width: 100%;
      height: var(--timer-height);
      background-color: var(--timer-background-color);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      transform: translateX(-100%); }
      .awssld__timer--animated {
        will-change: transform;
        transition: transform var(--timer-delay) linear; }
      .awssld__timer--run {
        transform: translateX(0); }
      .awssld__timer--fast {
        transition: transform calc(var(--timer-release) / 2) linear;
        transform: translateX(-0.00001px); }
      .awssld__timer--end {
        transform: translateX(-0.0001px);
        transition: transform var(--timer-release) linear; }
      .awssld__timer--hidden {
        display: none; }
  
  @keyframes slideFromLeft {
    from {
      transform: translate3d(-100%, 0, 0); }
    to {
      transform: translate3d(0, 0, 0); } }
  
  @keyframes slideFromRight {
    from {
      transform: translate3d(100%, 0, 0); }
    to {
      transform: translate3d(0, 0, 0); } }
  
  @keyframes slideToLeft {
    from {
      transform: translate3d(0, 0, 0); }
    to {
      transform: translate3d(-100%, 0, 0); } }
  
  @keyframes slideToRight {
    from {
      transform: translate3d(0, 0, 0); }
    to {
      transform: translate3d(100%, 0, 0); } }
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.clear {
  clear: both;
}

.Register-btn {
  font-family: "Roboto";
  color: #ffffff;
  background-color: transparent;
  padding: 8px 20px;
  border: 1px solid rgb(255, 255, 255);
  transition: all 0.3s ease-out;
  border-radius: 2px;
  cursor: pointer;
  font-size: 24px;
}

.Register-btn:hover {
  transition: all 0.3s ease-out;
  background: #224AA8;
  color: #ffff;
  transition: 250ms;
  border: 5px solid grey;
}


@media screen and (max-width: 1280px) { 
  .header-img {
    height: 200px;
    width: 200px;
  }
  .body-container{
    padding-bottom: 40px;
  }
  .body-container > h1 {
    font-size: 35px;
    padding-top: 50px;
  }
  .body-container > p {
    font-size: 18px;
  }
  .Register-btn {
    font-size: 20px;
    font-weight: 900;
  }
}

@media screen and (max-width: 960px) { 
  .header-img {
    height: 200px;
    width: 200px;
  }
  .body-container{
    padding-bottom: 40px;
  }
  .body-container > h1 {
    font-size: 35px;
    padding-top: 50px;
  }
  .body-container > p {
    font-size: 18px;
  }
  .Register-btn {
    font-size: 20px;
    font-weight: 900;
  }
}

@media screen and (max-width: 768px) { 
  .header-img {
    height: 90px;
    width: 90px;
  }
  .body-container{
    padding: 10px;
  }
  .body-container > h1 {
    font-size: 20px;
    padding-top: 10px;
  }
  .body-container > p {
    font-size: 12px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}

@media screen and (max-width: 450px) { 
  .header-img {
    height: 90px;
    width: 90px;
  }
  .body-container{
    padding: 10px;
  }
  .body-container > h1 {
    font-size: 20px;
    padding-top: 10px;
  }
  .body-container > p {
    font-size: 12px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}


@media screen and (max-width: 400px) { 
  .header-img {
    height: 90px;
    width: 90px;
  }
  .body-container{
    padding: 10px;
  }
  .body-container > h1 {
    font-size: 20px;
    padding-top: 10px;
  }
  .body-container > p {
    font-size: 12px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}




.background-image {
  background: url('/images/paddy_sd_min.jpg') center center/cover no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: top;
  text-align: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0);
  object-fit: contain;
}

.foreground-image {
  padding-top: 20px;
  width: 420px;
  height: 430px;
}

.background-image > h1 {
  font-size: 45px;
  padding-top:15px;
}

.background-image > p {
  font-size: 28px;
  padding-top: 5px;
}

.Register-btn {
  font-family: "Roboto";
  color: #ffffff;
  background-color: transparent;
  padding: 8px 10px;
  border: 1px solid rgb(255, 255, 255);
  transition: all 0.3s ease-out;
  border-radius: 2px;
  cursor: pointer;
  font-size: 32px;
  font-weight: 900;
}

.Register-btn:hover {
  transition: all 0.3s ease-out;
  background: #224AA8;
  color: #ffff;
  transition: 250ms;
  border: 5px solid grey;
}

.background-image > h1 {
  font-family: "Arial";
  color: #12285c;
}

.background-image > p {
  font-family: "Arial";
  color: #12285c;
  font-weight: 1000;
}

@media screen and (max-width: 1880px) {
  .foreground-image {
    padding-top: 20px;
    width: 390px;
    height: 400px;
  }
  .background-image > h1 {
    font-size: 40px;
    padding-top:15px;
  }
  .background-image > p {
    font-size: 24px;
    padding-top: 5px;
  }
  .Register-btn {
    font-size: 28px;
    font-weight: 900;
  }
}

@media screen and (max-width: 1720px) {
  .foreground-image {
    padding-top: 20px;
    width: 360px;
    height: 370px;
  }
  .background-image > h1 {
    font-size: 40px;
    padding-top:15px;
  }
  .background-image > p {
    font-size: 24px;
    padding-top: 5px;
  }
  .Register-btn {
    font-size: 28px;
    font-weight: 900;
  }
}

@media screen and (max-width: 1660px) {
  .foreground-image {
    padding-top: 20px;
    width: 260px;
    height: 270px;
  }
  .background-image > h1 {
    font-size: 30px;
    padding-top: 20px;
  }
  .background-image > p {
    font-size: 20px;
    padding-top: 10px;
  }
  .Register-btn {
    font-size: 28px;
    font-weight: 900;
  }
}

@media screen and (max-width: 1440px) {
  .foreground-image {
    padding-top: 20px;
    width: 220px;
    height: 230px;
  }
  .background-image > h1 {
    font-size: 26px;
    padding-top: 18px;
  }
  .background-image > p {
    font-size: 18px;
    padding-top: 10px;
  }
  .Register-btn {
    font-size: 28px;
    font-weight: 900;
  }
}

@media screen and (max-width: 1280px) {
  .foreground-image {
    padding-top: 18px;
    width: 200px;
    height: 210px;
  }
  .background-image > h1 {
    font-size: 22px;
    padding-top: 15px;
  }
  .background-image > p {
    font-size: 14px;
    padding-top: 9px;
  }
  .Register-btn {
    font-size: 22px;
    font-weight: 900;
  }
}


@media screen and (max-width: 1096px) {
  .foreground-image {
    padding-top: 15px;
    width: 180px;
    height: 190px;
  }
  .background-image > h1 {
    font-size: 20px;
    padding-top: 8px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 5px;
  }
  .Register-btn {
    font-size: 20px;
    font-weight: 900;
  }
}

@media screen and (max-width: 910px) {
  .foreground-image {
    padding-top: 15px;
    width: 140px;
    height: 150px;
  }
  .background-image > h1 {
    font-size: 18px;
    padding-top: 4px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 3px;
  }
  .Register-btn {
    font-size: 16px;
    font-weight: 900;
  }
}


@media screen and (max-width: 768px) {
  .foreground-image {
    padding-top: 10px;
    width: 120px;
    height: 130px;
  }
  .background-image > h1 {
    font-size: 20px;
    padding-top: 0px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}

@media screen and (max-width: 710px) {
  .foreground-image {
    padding-top: 10px;
    width: 100px;
    height: 110px;
  }
  .background-image > h1 {
    font-size: 18px;
    padding-top: 10px;
  }
  .background-image > p {
    font-size: 10px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}

@media screen and (max-width: 640px) {
  .foreground-image {
    padding-top: 10px;
    width: 70px;
    height: 80px;
  }
  .background-image > h1 {
    font-size: 12px;
    padding-top: 3px;
  }
  .background-image > p {
    font-size: 8px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 8px;
    font-weight: 800;
  }
}

@media screen and (max-width: 450px) {
  .foreground-image {
    padding-top: 10px;
    width: 120px;
    height: 130px;
  }
  .background-image > h1 {
    font-size: 20px;
    padding-top: 0px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}
@media screen and (max-width: 400px) {
  .foreground-image {
    padding-top: 10px;
    width: 120px;
    height: 130px;
  }
  .background-image > h1 {
    font-size: 20px;
    padding-top: 0px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}


.logoContainer {
  background: url('/SlideImage/sg_clubhouse-min.jpeg') center center/cover no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: top;
  text-align: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0);
  object-fit: contain;
}

.logoText{
  padding-top: 20px;
  width: 100%;
  height: 100%;
}


 .body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  padding-bottom: 250px;
}

.body-container > h1 {
  font-size: 50px;
  font-family : 'Roboto';
  color: #224AA8;
  padding-top: 40px;
}


.body-container > h2  {
  font-size: 35px;
  font-family : 'Roboto';
  color: #224AA8;
  text-align: center;
}


.text-block2 {
  position: initial;
  display: block;
  width: 55%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  background-color: white;
  color: black;
  opacity: 0.5;
}

.text-block2 > p {
  font-size: 30px;
  color: #000000;
  text-align: justify; 
  padding-top: 40px; 
  padding-bottom: 16px;
}

@media screen and (max-width: 1280px) {
  .text-block2 {
    width:80%;
  }
  .body-container {
    padding-bottom: 100px;
  }
  .body-container > h1 {
    font-size: 45px;
    padding-top: 40px;
  }

  .body-container > h2  {
    font-size: 22px;
  }
  .text-block2 > p {
    font-size: 18px;
    padding-top: 40px;
    padding-bottom: 0px;
  }
}


@media screen and (max-width: 1024px) {
  .text-block2 {
    width:80%;
  }
  .body-container {
    padding-bottom: 100px;
  }
  .body-container > h1 {
    font-size: 40px;
    padding-top: 40px;
  }

  .body-container > h2  {
    font-size: 20px;
  }
  .text-block2 > p {
    font-size: 18px;
    padding-top: 40px;
    padding-bottom: 0px;
  }
}


@media screen and (max-width: 608px) {
  .text-block2 {
    width:90%;
  }
  .body-container {
    padding-bottom: 100px;
  }
  .body-container > h1 {
    font-size: 35px;
    padding-top: 40px;
  }
    
    
  .body-container > h2  {
    font-size: 18px;
  }
  .text-block2 > p {
    font-size: 18px;
    padding-top: 40px; 
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 400px) {
  .body-container {
   padding-bottom: 100px;
  }
  .body-container > h1 {
    font-size: 30px;
    padding-top: 40px;
  }
  .body-container > h2  {
    font-size: 15px;
  }
  .text-block2 > p {
    font-size: 14px;
    padding-top: 40px; 
    padding-bottom: 0px;
  }
}

.projectcards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link_p {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(0, 89, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap_p {
    position: relative;
    width: 100%;
    padding-top:80%; /*67%*/
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info_p {
    padding: 20px 80px 20px;
  }
  
  .cards__item__text {
    color: #2E3C5C;
    font-size: 24px;
    line-height: 24px;
    font-family: 'Roboto';
    text-align: center;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 100%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
  
.mail-input {
  padding-top: 10px;
  border-radius: 2px;
  outline: none;
  border: none;
  font-size: 18px;
  border: 1px solid rgb(0, 0, 0);
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(7, 1, 1, 0.432);
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.name{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pages-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(7, 1, 1, 0.432);
  object-fit: contain;
}

.text-block {
  padding-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto
}
.map-h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
    font-family: "Roboto";
  }
  
  .google-map {
    width: 80%;
    height:500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 100px;
  }
  
  .pin-icon {
    width: 150px;
    color:dodgerblue;
  }
  
  .pin-text {
    font-size: 17px;
  }
  
  @media (max-width: 960px) {
    .google-map {
      height: 500px;
    }
  
    .map-h2 {
      font-size: 1.3rem;
      font-weight: 400;
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }

  @media (max-width: 768px) {
    .google-map {
      height: 300px;
    }
  
    .map-h2 {
      font-size: 1.3rem;
      font-weight: 900;
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }

.info {
    text-align: center;
    padding: 20px;
    background-color: var(--main-green);
    color: var(--white);
  }
  
  .info-h2 {
    text-transform: uppercase;
    font-weight: 300;
    margin: 0;
    font-family: "Roboto";
  }
  
  .info-detail {
    font-weight: 600;
    font-family: "Roboto";
    color:#2E3C5C;
  }
  
  .info-icons-container {
    display: flex;
    width: 100%;
  }
  
  .info-icon {
    font-size: 3rem;
    margin-right: 20px;
    cursor: pointer;
  }
  
  @media screen and (min-width: 699px) {
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px;
    }
  
    .info-detail {
      line-height: 2;
    }
  }

:root {
  --white: #ffffff;
  --red: #e31b23;
  --bodyColor: #ffffff;
  --borderFormEls :rgba(255, 255, 255, 1);
  --bgFormEls:rgba(255, 255, 255, 1);
  --bgFormElsFocus: rgba(255, 255, 255, 1);
}


.body-image {
  padding-top: 50px;
  width: 70%;
  margin: auto;
}

a {
  color: rgb(63, 63, 63);
}


input,
select,
textarea,
button {
  font-family: "Roboto";
  font-size: 14px;
}

label {
  color: rgb(0, 0, 0);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
}

select option{
  background: black;
}

/* Remove native arrow on IE */
select::-ms-expand {
  display: none;
}


select:-moz-focusring {
  color: black;
}

textarea {
  resize: none;
}

ul {
  list-style: none;
}

body {
  font: 18px/1.5 "Roboto";
  background: var(--bodyColor);
  color: var(--white);
}

.container {
  max-width: 700px;
  margin: 0 auto;
}
.container > h1{
  color: black;
}

.button-center {
  width: 200px;
  margin-left: auto;
  margin-right: auto;

}

.send-message {
  background-color:#ffffff;
  border: none;
  cursor: pointer;
  /*border-radius: 5%;*/
}

.send-message:hover{
  background-color: grey;
  color: white;
  border: 1px solid black;
}


/* FORM ELEMENTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form h1 {
  margin-bottom: 1.5rem;
}

.my-form li,
.my-form .grid > *:not(:last-child) {
  margin-bottom: 1.5rem;
}

.my-form select,
.my-form input,
.my-form textarea {
  width: 100%;
  line-height: 1.5;
  padding: 15px 10px;
  /*border: 1px solid var(--borderFormEls);*/
  color: black;
  transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
    transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.my-form textarea {
  height: 170px;
}

.my-form ::-webkit-input-placeholder {
  color: grey;
  /*Fix opacity issue on Firefox*/
  opacity: 1;
}

.my-form ::placeholder {
  color: grey;
  /*Fix opacity issue on Firefox*/
  opacity: 1;
}

.my-form select:focus,
.my-form input:focus,
.my-form textarea:focus,
.my-form button:enabled:hover,
.my-form button:focus,
.my-form input[type="checkbox"]:focus + label {
  background: var(--bgFormElsFocus);
}

.my-form select:focus,
.my-form input:focus,
.my-form textarea:focus {
  transform: scale(1.02);
}



/* FORM BTNS
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form .required-msg {
  display: none;
  padding-left: 20px;
}

.my-form .btn-grid {
  position: relative;
  overflow: hidden;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
}

.my-form button {
  font-weight: bold;
}

.my-form button > * {
  display: inline-block;
  width: 100%;
  transition: transform 0.4s ease-in-out;
}

.my-form button .back {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-110%, -50%);
}

.my-form button:enabled:hover .back,
.my-form button:focus .back {
  transform: translate(-50%, -50%);
}

.my-form button:enabled:hover .front,
.my-form button:focus .front {
  transform: translateX(110%);
}


/* CUSTOM CHECKBOX
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.my-form input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.my-form input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  padding-left: 2rem;
  transition: background 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
}

.my-form input[type="checkbox"] + label::before,
.my-form input[type="checkbox"] + label::after {
  content: '';
  position: absolute;
}

.my-form input[type="checkbox"] + label::before {
  left: 0;
  top: 6px;
  width: 18px;
  height: 18px;
  /*border: 2px solid var(--white);*/
}

.my-form input[type="checkbox"]:checked + label::before {
  background: var(--red);
}

.my-form input[type="checkbox"]:checked + label::after {
  left: 7px;
  top: 7px;
  width: 6px;
  height: 14px;
  /*border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);*/
  transform: rotate(45deg);
}



/* MQ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (min-width: 600px) {
  .my-form .grid {
    display: grid;
    grid-gap: 1.5rem;
  }

  .my-form .grid-2 {
    grid-template-columns: 1fr 1fr;
  }

  .my-form .grid-3 {
    grid-template-columns: auto auto auto;
    align-items: center;
  }

  .my-form .grid > *:not(:last-child) {
    margin-bottom: 0;
  }

  .my-form .required-msg {
    display: block;
  }
}

@media screen and (min-width: 541px) {
  .my-form input[type="checkbox"] + label::before {
    top: 50%;
    transform: translateY(-50%);
  }

  .my-form input[type="checkbox"]:checked + label::after {
    top: 3px;
  }
}

@media screen and (max-width: 500px) {
  .my-form{
    width: 100%;
  }
}

input[type="text"] + label
 {
  position: absolute;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #1f1f1f;
  cursor: text;
  transform-origin: top left;
  transition: all 0.1s;
  transform: translate(20px, -35px) scale(1);
}

input[type="text"]:focus + label,
input[type="text"]:not([value=""]) + label
 {
  transform: translate(10px,-50px) scale(0.7);
  color: none;
}

.bullets {
  list-style: circle;
  margin-left: 40px;
}

.header-project {
    font-family: Roboto;
    color: #224AA8;
    padding: 50px;
}

.text-block-project {
    position: initial;
    display: block;
    padding-top: 60px;
    padding-bottom: 60px;
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    background-color: white;
    color: black;
    opacity: 0.5;
    font-size: 20;
    text-align: justify;
}


@media screen and (max-width: 1280px) { 
    .header-project {
      font-size: 35px;
      padding: 50px;
    }
    .text-block-project {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 960px) { 
    .header-project {
      font-size: 35px;
      padding: 50px;
    }
    .text-block-project {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 768px) { 
    .header-project {
      font-size: 20px;
      padding: 10px;
    }
    .text-block-project {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 450px) { 
    .header-project {
      font-size: 20px;
      padding: 10px;
    }
    .text-block-project {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 400px) { 
    .header-project {
        font-size: 20px;
        padding: 10px;
    }
    .text-block-project {
    font-size: 12px;
    }
}
