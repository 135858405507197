.bullets {
  list-style: circle;
  margin-left: 40px;
}

.header-project {
    font-family: Roboto;
    color: #224AA8;
    padding: 50px;
}

.text-block-project {
    position: initial;
    display: block;
    padding-top: 60px;
    padding-bottom: 60px;
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    background-color: white;
    color: black;
    opacity: 0.5;
    font-size: 20;
    text-align: justify;
}


@media screen and (max-width: 1280px) { 
    .header-project {
      font-size: 35px;
      padding: 50px;
    }
    .text-block-project {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 960px) { 
    .header-project {
      font-size: 35px;
      padding: 50px;
    }
    .text-block-project {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 768px) { 
    .header-project {
      font-size: 20px;
      padding: 10px;
    }
    .text-block-project {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 450px) { 
    .header-project {
      font-size: 20px;
      padding: 10px;
    }
    .text-block-project {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 400px) { 
    .header-project {
        font-size: 20px;
        padding: 10px;
    }
    .text-block-project {
    font-size: 12px;
    }
}