video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.clear {
  clear: both;
}

.Register-btn {
  font-family: "Roboto";
  color: #ffffff;
  background-color: transparent;
  padding: 8px 20px;
  border: 1px solid rgb(255, 255, 255);
  transition: all 0.3s ease-out;
  border-radius: 2px;
  cursor: pointer;
  font-size: 24px;
}

.Register-btn:hover {
  transition: all 0.3s ease-out;
  background: #224AA8;
  color: #ffff;
  transition: 250ms;
  border: 5px solid grey;
}


@media screen and (max-width: 1280px) { 
  .header-img {
    height: 200px;
    width: 200px;
  }
  .body-container{
    padding-bottom: 40px;
  }
  .body-container > h1 {
    font-size: 35px;
    padding-top: 50px;
  }
  .body-container > p {
    font-size: 18px;
  }
  .Register-btn {
    font-size: 20px;
    font-weight: 900;
  }
}

@media screen and (max-width: 960px) { 
  .header-img {
    height: 200px;
    width: 200px;
  }
  .body-container{
    padding-bottom: 40px;
  }
  .body-container > h1 {
    font-size: 35px;
    padding-top: 50px;
  }
  .body-container > p {
    font-size: 18px;
  }
  .Register-btn {
    font-size: 20px;
    font-weight: 900;
  }
}

@media screen and (max-width: 768px) { 
  .header-img {
    height: 90px;
    width: 90px;
  }
  .body-container{
    padding: 10px;
  }
  .body-container > h1 {
    font-size: 20px;
    padding-top: 10px;
  }
  .body-container > p {
    font-size: 12px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}

@media screen and (max-width: 450px) { 
  .header-img {
    height: 90px;
    width: 90px;
  }
  .body-container{
    padding: 10px;
  }
  .body-container > h1 {
    font-size: 20px;
    padding-top: 10px;
  }
  .body-container > p {
    font-size: 12px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}


@media screen and (max-width: 400px) { 
  .header-img {
    height: 90px;
    width: 90px;
  }
  .body-container{
    padding: 10px;
  }
  .body-container > h1 {
    font-size: 20px;
    padding-top: 10px;
  }
  .body-container > p {
    font-size: 12px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}



