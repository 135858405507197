.map-h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
    font-family: "Roboto";
  }
  
  .google-map {
    width: 80%;
    height:500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 100px;
  }
  
  .pin-icon {
    width: 150px;
    color:dodgerblue;
  }
  
  .pin-text {
    font-size: 17px;
  }
  
  @media (max-width: 960px) {
    .google-map {
      height: 500px;
    }
  
    .map-h2 {
      font-size: 1.3rem;
      font-weight: 400;
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }

  @media (max-width: 768px) {
    .google-map {
      height: 300px;
    }
  
    .map-h2 {
      font-size: 1.3rem;
      font-weight: 900;
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }
