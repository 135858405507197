.info {
    text-align: center;
    padding: 20px;
    background-color: var(--main-green);
    color: var(--white);
  }
  
  .info-h2 {
    text-transform: uppercase;
    font-weight: 300;
    margin: 0;
    font-family: "Roboto";
  }
  
  .info-detail {
    font-weight: 600;
    font-family: "Roboto";
    color:#2E3C5C;
  }
  
  .info-icons-container {
    display: flex;
    width: 100%;
  }
  
  .info-icon {
    font-size: 3rem;
    margin-right: 20px;
    cursor: pointer;
  }
  
  @media screen and (min-width: 699px) {
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px;
    }
  
    .info-detail {
      line-height: 2;
    }
  }
