
 .body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  padding-bottom: 250px;
}

.body-container > h1 {
  font-size: 50px;
  font-family : 'Roboto';
  color: #224AA8;
  padding-top: 40px;
}


.body-container > h2  {
  font-size: 35px;
  font-family : 'Roboto';
  color: #224AA8;
  text-align: center;
}


.text-block2 {
  position: initial;
  display: block;
  width: 55%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  background-color: white;
  color: black;
  opacity: 0.5;
}

.text-block2 > p {
  font-size: 30px;
  color: #000000;
  text-align: justify; 
  padding-top: 40px; 
  padding-bottom: 16px;
}

@media screen and (max-width: 1280px) {
  .text-block2 {
    width:80%;
  }
  .body-container {
    padding-bottom: 100px;
  }
  .body-container > h1 {
    font-size: 45px;
    padding-top: 40px;
  }

  .body-container > h2  {
    font-size: 22px;
  }
  .text-block2 > p {
    font-size: 18px;
    padding-top: 40px;
    padding-bottom: 0px;
  }
}


@media screen and (max-width: 1024px) {
  .text-block2 {
    width:80%;
  }
  .body-container {
    padding-bottom: 100px;
  }
  .body-container > h1 {
    font-size: 40px;
    padding-top: 40px;
  }

  .body-container > h2  {
    font-size: 20px;
  }
  .text-block2 > p {
    font-size: 18px;
    padding-top: 40px;
    padding-bottom: 0px;
  }
}


@media screen and (max-width: 608px) {
  .text-block2 {
    width:90%;
  }
  .body-container {
    padding-bottom: 100px;
  }
  .body-container > h1 {
    font-size: 35px;
    padding-top: 40px;
  }
    
    
  .body-container > h2  {
    font-size: 18px;
  }
  .text-block2 > p {
    font-size: 18px;
    padding-top: 40px; 
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 400px) {
  .body-container {
   padding-bottom: 100px;
  }
  .body-container > h1 {
    font-size: 30px;
    padding-top: 40px;
  }
  .body-container > h2  {
    font-size: 15px;
  }
  .text-block2 > p {
    font-size: 14px;
    padding-top: 40px; 
    padding-bottom: 0px;
  }
}
