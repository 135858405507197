.mail-input {
  padding-top: 10px;
  border-radius: 2px;
  outline: none;
  border: none;
  font-size: 18px;
  border: 1px solid rgb(0, 0, 0);
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(7, 1, 1, 0.432);
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.name{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pages-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(7, 1, 1, 0.432);
  object-fit: contain;
}

.text-block {
  padding-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto
}