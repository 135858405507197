.background-image {
  background: url('/images/paddy_sd_min.jpg') center center/cover no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: top;
  text-align: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0);
  object-fit: contain;
}

.foreground-image {
  padding-top: 20px;
  width: 420px;
  height: 430px;
}

.background-image > h1 {
  font-size: 45px;
  padding-top:15px;
}

.background-image > p {
  font-size: 28px;
  padding-top: 5px;
}

.Register-btn {
  font-family: "Roboto";
  color: #ffffff;
  background-color: transparent;
  padding: 8px 10px;
  border: 1px solid rgb(255, 255, 255);
  transition: all 0.3s ease-out;
  border-radius: 2px;
  cursor: pointer;
  font-size: 32px;
  font-weight: 900;
}

.Register-btn:hover {
  transition: all 0.3s ease-out;
  background: #224AA8;
  color: #ffff;
  transition: 250ms;
  border: 5px solid grey;
}

.background-image > h1 {
  font-family: "Arial";
  color: #12285c;
}

.background-image > p {
  font-family: "Arial";
  color: #12285c;
  font-weight: 1000;
}

@media screen and (max-width: 1880px) {
  .foreground-image {
    padding-top: 20px;
    width: 390px;
    height: 400px;
  }
  .background-image > h1 {
    font-size: 40px;
    padding-top:15px;
  }
  .background-image > p {
    font-size: 24px;
    padding-top: 5px;
  }
  .Register-btn {
    font-size: 28px;
    font-weight: 900;
  }
}

@media screen and (max-width: 1720px) {
  .foreground-image {
    padding-top: 20px;
    width: 360px;
    height: 370px;
  }
  .background-image > h1 {
    font-size: 40px;
    padding-top:15px;
  }
  .background-image > p {
    font-size: 24px;
    padding-top: 5px;
  }
  .Register-btn {
    font-size: 28px;
    font-weight: 900;
  }
}

@media screen and (max-width: 1660px) {
  .foreground-image {
    padding-top: 20px;
    width: 260px;
    height: 270px;
  }
  .background-image > h1 {
    font-size: 30px;
    padding-top: 20px;
  }
  .background-image > p {
    font-size: 20px;
    padding-top: 10px;
  }
  .Register-btn {
    font-size: 28px;
    font-weight: 900;
  }
}

@media screen and (max-width: 1440px) {
  .foreground-image {
    padding-top: 20px;
    width: 220px;
    height: 230px;
  }
  .background-image > h1 {
    font-size: 26px;
    padding-top: 18px;
  }
  .background-image > p {
    font-size: 18px;
    padding-top: 10px;
  }
  .Register-btn {
    font-size: 28px;
    font-weight: 900;
  }
}

@media screen and (max-width: 1280px) {
  .foreground-image {
    padding-top: 18px;
    width: 200px;
    height: 210px;
  }
  .background-image > h1 {
    font-size: 22px;
    padding-top: 15px;
  }
  .background-image > p {
    font-size: 14px;
    padding-top: 9px;
  }
  .Register-btn {
    font-size: 22px;
    font-weight: 900;
  }
}


@media screen and (max-width: 1096px) {
  .foreground-image {
    padding-top: 15px;
    width: 180px;
    height: 190px;
  }
  .background-image > h1 {
    font-size: 20px;
    padding-top: 8px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 5px;
  }
  .Register-btn {
    font-size: 20px;
    font-weight: 900;
  }
}

@media screen and (max-width: 910px) {
  .foreground-image {
    padding-top: 15px;
    width: 140px;
    height: 150px;
  }
  .background-image > h1 {
    font-size: 18px;
    padding-top: 4px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 3px;
  }
  .Register-btn {
    font-size: 16px;
    font-weight: 900;
  }
}


@media screen and (max-width: 768px) {
  .foreground-image {
    padding-top: 10px;
    width: 120px;
    height: 130px;
  }
  .background-image > h1 {
    font-size: 20px;
    padding-top: 0px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}

@media screen and (max-width: 710px) {
  .foreground-image {
    padding-top: 10px;
    width: 100px;
    height: 110px;
  }
  .background-image > h1 {
    font-size: 18px;
    padding-top: 10px;
  }
  .background-image > p {
    font-size: 10px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}

@media screen and (max-width: 640px) {
  .foreground-image {
    padding-top: 10px;
    width: 70px;
    height: 80px;
  }
  .background-image > h1 {
    font-size: 12px;
    padding-top: 3px;
  }
  .background-image > p {
    font-size: 8px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 8px;
    font-weight: 800;
  }
}

@media screen and (max-width: 450px) {
  .foreground-image {
    padding-top: 10px;
    width: 120px;
    height: 130px;
  }
  .background-image > h1 {
    font-size: 20px;
    padding-top: 0px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}
@media screen and (max-width: 400px) {
  .foreground-image {
    padding-top: 10px;
    width: 120px;
    height: 130px;
  }
  .background-image > h1 {
    font-size: 20px;
    padding-top: 0px;
  }
  .background-image > p {
    font-size: 12px;
    padding-top: 2px;
  }
  .Register-btn{
    font-size: 15px;
    font-weight: 1000;
  }
}


.logoContainer {
  background: url('/SlideImage/sg_clubhouse-min.jpeg') center center/cover no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: top;
  text-align: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0);
  object-fit: contain;
}

.logoText{
  padding-top: 20px;
  width: 100%;
  height: 100%;
}
